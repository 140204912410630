
export default function eventsPageInit() {
  if ($('.offset-content-wrapper:visible').length) {
    setInterval(() => {
      const sideContentRect = $('.offset-content-wrapper:visible')[0].getBoundingClientRect()
      const descriptionRect = $('.section-event-description')[0].getBoundingClientRect()

      if (descriptionRect.bottom > sideContentRect.bottom) {
        $('.section-event-description').addClass('tall')
      } else {
        $('.section-event-description').removeClass('tall')
      }
    }, 200)
  }
}
