import { present } from '../lib/util'

export function autoplayInit() {
  $(document).on('shown.bs.tab', (evt) => {
    const tab = $(evt.target).attr('href')
    if (!tab) {
      return
    }

    const $tab = $(tab)
    const $iframe = $tab.data('src') ? $tab : $tab.find('[data-src]')
    if ($iframe.data('src')) {
      const src = addAutoplayParam($iframe.data('src'))
      $iframe.attr('src', src)
      return
    }

    const $audio = $tab.find('audio[src]') as JQuery<HTMLAudioElement>
    if (present($audio.attr('src'))) {
      $audio.each((_, elem: HTMLAudioElement) => {
        elem.play()
      })
      return
    }
  })

  const hash = location.hash
  if (hash) {
    setTimeout(() =>
      $(`[data-toggle="tab"][href="${hash}"]`).click(),
    400,
    )
  }
}

function addAutoplayParam(src: string) {
  let url: URL
  try {
    url = new URL(src)
  } catch (ex) {
    return src + '?autoplay=true'
  }

  url.searchParams.set('autoplay', 'true')
  return url.toString()
}
