
import 'google.analytics'

export default function() {
  if (typeof ga != 'undefined') {
    try {
      const gaTracker = ga.getAll()[0]

      $('.section-faq').on('show.bs.collapse', (evt) => {
        if (evt.target.hasAttribute('data-event-label')) {
          gaTracker.send('event', {
            eventCategory: 'FAQ',
            eventAction: 'expand',
            eventLabel: evt.target.getAttribute('data-event-label'),
          })
        }
      })

      $('.section-faq').on('hide.bs.collapse', (evt) => {
        if (evt.target.hasAttribute('data-event-label')) {
          gaTracker.send('event', {
            eventCategory: 'FAQ',
            eventAction: 'collapse',
            eventLabel: evt.target.getAttribute('data-event-label'),
          })
        }
      })

    } catch (err) {
      console.error(err)
    }
  }
}
