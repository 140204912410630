export function signInInit() {
  const elems = document.querySelectorAll('a[href=\'/sign_in\']')
  const qparams = {
    origin: window.location.pathname,
  }

  Array.prototype.forEach.call(elems, (el) => {
    el.href += `?${$.param(qparams)}`
  })
}
