import * as $ from 'jquery'

export default function(
  /** The section that the menu points to */
  $element: JQuery<HTMLElement>,
  /**
   * The height of the menu overlay,
   * i.e. if the menu takes up 48 pixels of real estate this should be 48.0
   */
  buffer: number,
  /**
   * The scroll container, which defaults to the window
   */
  container: JQuery<Window> | JQuery<Document> | JQuery<HTMLElement> = $(window),
): boolean {
  if (!$element || !$element.offset()) {
    return false
  }

  /** This represents how far down the page (or scroll container) that we have scrolled, in pixels */
  const scrollTop = container.scrollTop()
  /** This is how far down the page our section is, in pixels */
  const offset = $element.offset()
  /** The height of the section, in pixels */
  const height = $element.height()
  if (scrollTop == undefined || offset == undefined || height == undefined) {
    return false
  }

  // If we have not yet scrolled where the top of the section (the offset) touches the bottom of the menu overlay,
  // we are not yet in view.
  if (scrollTop < offset.top - buffer) {
    return false
  }

  // If we have scrolled to where the bottom of the menu is past the bottom of the section
  // (offset.top + height), then we are not in view.
  if (scrollTop >= offset.top + height - buffer) {
    return false
  }

  return true
}
