import * as $ from 'jquery'

function isEmpty( el: JQuery<HTMLElement> ){
  return !$.trim(el.html())
}

const getScrollAmount = (beforeContext: JQuery<HTMLElement>) => {
  if (isEmpty(beforeContext)) {
    return 0
  } else {
    let amount = beforeContext.height() || 0
    amount -= 80

    return amount
  }
}

export default function scriptureQuote() {
  const scrollContainer = document.querySelector('.scripture-quote__scripture')
  const beforeContext = $('.before-key-verse-context')
  const keyVerseHeight = $('.key-verse').height()
  const heightOffset = 125
  
  if (keyVerseHeight) {
    const verseWindow = keyVerseHeight + heightOffset
    const style = document.createElement('style')
    style.type = 'text/css'

    style.innerHTML = `
      .scripture-quote .collapse:not(.show) { display: block; height: ${verseWindow}px}
      .scripture-quote .collapse:not(.show) .scripture-quote__scripture { height: ${verseWindow}px}
      .scripture-quote .collapsing { height: ${verseWindow}px }
    `
    document.getElementsByTagName('head')[0].appendChild(style)  

    if (!scrollContainer) { return }

    scrollContainer.scrollTo({
      top: getScrollAmount(beforeContext),
      left: 0,
      behavior: 'smooth',
    })

    $('#scripture-quote').on('hide.bs.collapse', function () {
      $('.gradient-overlay').css('opacity', 1)
    })

    $('#scripture-quote').on('hidden.bs.collapse', function () {
      scrollContainer.scrollTo({
        top: getScrollAmount(beforeContext),
        left: 0,
        behavior: 'smooth',
      })
    })

    $('#scripture-quote').on('show.bs.collapse', function () {
      $('.gradient-overlay').css('opacity', 0)
    })
  }
}
