import bugsnagClient from 'bugsnag'

export function sharingInit() {
  $('[data-share=facebook]').click(function(this: any) {
    if (event) { event.preventDefault() }
    fbShare.call(this).catch((ex) => bugsnagClient.notify(ex))
  })
  $('[data-share=twitter]').click(function(this: any) {
    if (event) { event.preventDefault() }
    twitterShare.call(this).catch((ex) => bugsnagClient.notify(ex))
  })
  $('[data-share=copy]').click(function(this: any) {
    if (event) { event.preventDefault() }
    copyLink.call(this).catch((ex) => bugsnagClient.notify(ex))
  })

  initQrCode($('[data-share="qrcode"]'))

  $('#breadcrumb-share').on('hide.bs.dropdown', (e) => {
    const target = event && event.target
    if (target) {
      if ($('#breadcrumb-share-dropdown').toArray().find((elem) =>
        $.contains(elem, target as Element))) {
        // don't hide the dropdown
        e.preventDefault()
        return
      }
    }
  })
}

export async function copyLink(this: HTMLAnchorElement, href?: string) {
  if (event) { event.preventDefault() }

  href = href || getCanonical()
  if (!href) { return }

  try {
    await navigator.clipboard.writeText(href)
    $(this).find('span').text('Link Copied')
  } catch (ex) {
    console.error(ex)
    $(this).find('span').text('Could not copy link!')
  }
}

export async function twitterShare(href?: string) {
  href = href || getCanonical()

  const url = `https://twitter.com/intent/tweet?url=${href}&via=WatermarkChurch`
  window.open(url, 'popup', 'width=600,height=600')
}

export async function fbShare(href?: string): Promise<void> {
  href = href || getCanonical()

  const url = `https://www.facebook.com/sharer/sharer.php?u=${href}`
  window.open(url, 'popup', 'width=600,height=600')
}

import QRCode from 'easyqrcodejs'
function initQrCode($anchor: JQuery) {
  const href = $anchor.attr('href') || getCanonical()
  if (!href) { return }

  const url = new URL(href)
  url.searchParams.set('utm_source', 'share_qr_code')

  let $qrCodeContainer = $anchor.find('.qrcode__container')
  if (!$qrCodeContainer.length) {
    $anchor.append('<div class="qrcode__container">')
    $qrCodeContainer = $anchor.find('.qrcode__container')
  }

  const _qr = new QRCode($qrCodeContainer[0], {
    text: url.toString(),
    width: 150,
    height: 150,
    colorDark: '#000000',
    colorLight: '#ffffff',
    correctLevel: QRCode.CorrectLevel.H,
    drawer: 'svg',
  })

  $anchor.click((event) => {
    if (event) { event.preventDefault() }
    downloadSVG(document.title, $qrCodeContainer[0].innerHTML)
  })
}

function getCanonical(): string | undefined {
  const canonical = document.querySelector('link[rel=\'canonical\']')
  if (canonical) {
    return canonical.getAttribute('href') || undefined
  }
}

function downloadSVG(filename: string, text: string) {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
