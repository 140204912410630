export default function() {
  let index = 1
  const lineTimers = $('.line-timer-container:eq(1)').children()

  const increment = (currentIndex: number) => {
    if ( currentIndex === lineTimers.length - 1) {
      index = 0
    } else {
      index++
    }
  }

  $('.initiative-carousel').on('slid.bs.carousel', (_evt) => {
    if (index === 0) {
      lineTimers.each((_, el) => {
        $(el).removeClass('timer-animation')
        // necessary to "restart" the animation
        // https://css-tricks.com/restart-css-animation/
        $(el).offset()
      })
    }

    lineTimers.eq(index).addClass('timer-animation')
    increment(index)
  })
}
