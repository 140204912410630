export default function() {
  $('.initiative').each((i, el) => {
    $(el).on('mouseenter', () => {
      $(`.initiative-image-${i + 1}`).addClass('active')
    })

    $(el).on('mouseleave', () => {
      $(`.initiative-image-${i + 1}`).removeClass('active')
    })
  })
}
