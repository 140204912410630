import * as $ from 'jquery'

export default function() {
  $(() => {
    [
      '#message-transcript-toggle-button',
      '#message-sermon-guide-toggle-button',
    ].forEach((selector) => {
      const $link = $(selector)
      const target = $link.data('target') || $link.attr('href')

      $link.on('click', () => {
        $(target).removeClass('text-fade')
        $link.remove().off('click')
      })
    })

  })
}
